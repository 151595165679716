$green: #719527;
$light-green: #5cb85b;

#promotions {
  margin-top: 9em;
  margin-bottom: 3em;

  .contact-info {
      .icon {
        font-size: 24px;
      }
    .contact {
      box-shadow: 0px 1px 3px 0px #d0d0d0;
      padding: 10px 30px;
      border-radius: 5px;
       margin: 0 8px;
       a {
         color: #719526;
         text-decoration: underline;
         font-size: 14px;
       }
    }
    @media screen and (max-width: 650px) {
      flex-direction: column;
      .contact {
        box-shadow: none;
        display: flex;
        justify-content: center;
        padding: 2px;
      }
    }
  }

  .promotion-btns {
    .btn {
      color: green;
      background-color: white;
      border: 2px solid #719528;
      // border-radius: 40px;
      box-shadow: 0px 5px 5px #00000026;
    }
  }

  .nav-tabs {
    .nav-link {
      &:not(.active):hover {
        border-color: transparent;
      }
    }
    .nav-item {
      border: 1px solid #e6e6e6;
      // border-top-left-radius: 0.25rem;
      // border-top-right-radius: 0.25rem;
      border-bottom: none;
    }
  }

  .nav-link {
    color: #719526;
    &.active {
      border-top-color: #719526;
      border-top-width: 3px;
    }

    .nav-item {
      margin-bottom: -1px;
    }
  }

  .promotion-container {
    // column-count: 2;
    column-gap: 1.5em;
    @media screen and (max-width: 992px) {
      column-count: initial;
    }
    .promotion {
      position: relative;
      display: flex;
      border: 1px solid #e6e6e6;
      box-shadow: 0px 2px 10px 0px #cccccc;
      // border-radius: 10px;
      break-inside: avoid;
      margin-bottom: 1.5em;
      @media (max-width: 991px) {
        flex-direction: column;
      }

      .link {
        width: 50%;
        @media (max-width: 991px) {
          width: 100%;
        }
      }

      .promotion-img-slide {
        width: 100%;
        height: 100%;

        .slick-list,
        .slick-track {
          width: 100%;
          height: 100%;
          img {
            // height: 100%;
            // object-fit: cover;
          }
        }
        .slick-slide {
          width: 100%;
        }
      }

      .images {
        flex: 1;
        object-fit: cover;
        overflow: hidden;
        // border-top-left-radius: 10px;
        // border-bottom-left-radius: 10px;
        @media (max-width: 991px) {
          // border-top-right-radius: 10px;
          // border-bottom-left-radius: initial;
        }

        // img {
        //   height: 100%;
        //   width: 100%;
        //   object-fit: cover;
        //   border-top-left-radius: 10px;
        //   border-bottom-left-radius: 10px;
        //   transition: all .2s;
        //   &:hover {
        //     transform: scale(1.05);
        //   }
        //   @media (max-width: 991px) {
        //     border-top-right-radius: 10px;
        //     border-bottom-left-radius: initial;
        //   }
        // }
      }

      .info {
        flex: 1;
        padding: 1em;
        text-align: left;
        &.padding-top {
          margin-top: 2em;
        }
      }
      .title {
        font-size: 22px;
        font-weight: 600;
        color: $green;
      }
      .desc {
        font-size: 0.9em;
      }
      .more-details-btn {
        text-align: center;
        @media screen and (min-width: 992px) {
          text-align: initial;
        }
      }
      .type {
        background-color: $light-green;
        display: inline-block;
        position: absolute;
        left: -10px;
        padding: 0.5em 2em;
        top: 15px;
        color: white;
        border-radius: 5px;
      }
      img {
        // border-bottom-left-radius: 10px;
        // border-bottom-right-radius: 10px;
      }
    }
  }
}

.promotion-controller {
  background: white;
  padding: 1em;
  border: 2px solid $black100;
  .btn, button {
    width: 100%;
  }
}

.contact {
  font-size: 14px;
  text-align: left;
  list-style: none;
  padding: 0;
  .icon {
    font-size: 22px;
    padding-right: 10px;
  }
  a {
    color: #719526;
    &:hover {
      text-decoration: none;
      color: #719526;
    }
  }
}
