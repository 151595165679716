#events {
  p {
    color: $grey400;
    line-height: 2em;
    @media (min-width: 992px) {
      font-size: 1.25em;
    }
  }
  .room-specs {
    .left .spec{
      justify-content: flex-end;
      text-align: right;
    }
    .right .spec{
      justify-content: flex-start;
    }
    .spec {
      display: flex;
      align-items: center;
      margin: 1em 0;
      min-height: 3em;
      img {
        width: 32px;
      }
    }
    .logo {
      font-size: 2em;
      padding: 0 0.5em;
      color: $green300;
    }
  }
 .title-text {
    color: $grey400;
    line-height: 2em;
    font-size: 1.25em;
    margin: 5em 0 3em;
  }
  .room {
    border-radius: 0;
    border: none;
    box-shadow: 0px 5px 10px 0px $grey200;
    margin-left: 1px;
    margin-right: 1px;
    .name {
      margin-top: 2em;
      margin-bottom: 1.5em;
      font-size: 1.6em;
      @media (max-width: 991px) {
        margin-top: 1em;
      }
    }
    .room-img {
      height: 300px;
    }
    ul.main-info {
      & > li {
        padding-bottom: 1em;
      }
    }
    ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
      [class^="icon-"], [class*=" icon-"] {
        font-size: 1.5em;
        color: $green400;
        margin-right: 0.8em;
      }
      li {
        display: flex;
        text-align: left;
        line-height: 1.75em;
      }
    }
  }
  .title {
    font-size: 0.8em;
  }
  .main-info {
    font-size: 0.8em;
  }
  h5 {
    p {
      line-height: 1.5em;
      display: inline-block;
      border-top: 1px solid $grey300;
      border-bottom: 1px solid $grey300;
      padding: 1em 2em;
      margin: 1em 0;
    }
  }
  .left-image {
    background-image: url('/assets/images/slides/banquet_1.jpg');
    height: 100%;
    @media (max-width: 991px) {
      height: 300px;
      margin-bottom: 6px;
    }
  }
  .images {
    display: flex;
    img {
      height: 250px;
      width: 100%;
      object-fit: cover;
    }
  }
  .right-images {
    .top-image {
      background-image: url('/assets/images/conference/banquet_3.jpg');
      height: 350px;
      margin-left: 6px;
      margin-bottom: 3px;
      background-position-y: 65%;
      @media (max-width: 991px) {
        margin-left: initial;
      }
    }
    .bottom-image {
      background-image: url('/assets/images/conference/banquet_4.jpg');
      height: 250px;
      margin-left: 6px;
      margin-top: 3px;
      background-position-y: 90%;
      @media (max-width: 991px) {
        margin-left: initial;
      }
    }
  }
}
