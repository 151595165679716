#dine {
  p {
    color: $grey400;
    line-height: 2em;
    @media (min-width: 992px) {
      font-size: 1.25em;
    }
  }
  #breakfast {
    .bg-img {
      border: 5px solid white;
    }
    .breakfast-img {
      height: 560px;
      border-left: none;
    }
    .side-breakfast-img {
      height: 280px;
      width: 100%;
      border-right: none;
    }
    .message {
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 4em;
      background-color: rgba(255, 255, 255, 0.75);
      margin-right: 5px;
      padding: 1em 2em;
      line-height: 1.75em;
      @media (min-width: 992px) {
        width: 80%;
      }
    }
  }

  .foods {
    .img-frame {
      height: 250px;
    }
    .play-font {
      font-size: 0.85em;
      line-height: 1.75em;
      color: $grey400;
    }
  }
  .reservation {
    margin-top: 2em;
    margin-bottom: 5em;
    .contact {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        font-size: 0.75em;
      }
    }
  }

  #restaurant {
    div[class^="col-"],
    div[class*=" col-"] {
      &:first-child {
        .bg-img {
          border-left: none;
        }
      }
      &:last-child {
        .bg-img {
          border-right: none;
        }
      }
    }
    .bg-img {
      height: 300px;
      border: 5px solid white;

      // &:first-child {
      //   border-left: none;
      // }
      // &:last-child {
      //   border-right: none;
      // }
    }
  }
  .review-btn {
    border: 1px solid $grey100;
    box-shadow: 0px 3px 2px $grey300;
    padding: 1em 4em;
    letter-spacing: 1px;
    margin-left: 0.5em;
    margin-right: 0.5em;
    min-width: 350px;
    vertical-align: top;
  }
  .menu-btn {
     padding: 0.5em 2em;
     padding: 0.75em 2em;
     border-radius: 50px;
     border: navajowhite;
     color: white;
     background: linear-gradient(0deg, rgba(255,247,59,1) 0%, rgba(0,91,6,1) 100%);
     letter-spacing: 2px;
     outline: none;
     font-size: 18px;
     cursor: pointer;
     box-shadow: 0px 1px 4px 0px #b3b3b3;
     margin: 10px 1em;
  }

  .trip-advisor {
    border: 1px solid #34e0a1;
    display: flex;
    box-shadow: 0px 3px 2px #dcdcdc;
    border-radius: 0.25em;
    padding: 1em 4em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    background-color: white;
    .trip_advisor_restaurant {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #CDSWIDREST.cx_brand_refresh .wrapper {
      border: none;
    }
  }

  .link-container {
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

}

.food-menu {
  .btn {
    padding: 1em 2em;
  }
  .icon {
    font-size: 1.5em;
  }
}
.modal-breakfast {
  max-width: 60%;
  text-align: center;
  @media (max-width: 768px) {
    max-width: initial;
  }
}

