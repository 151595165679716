$green: #719527;

#green_awards {
  margin-top: 18em;
  margin-bottom: 5em;
  .awards {
    img {
      width: 350px;
    }
  }
  .green {
    color: $green;
  }
  .images {
    display: grid;
    grid-template-columns: 50% 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 10px;
    }
  }
  p {
    margin-bottom: 0.5em;
  }
}
