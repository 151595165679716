$black100: #4A4A4A;

$green100: #3B9B43;
$green200: #92994D;
$green300: #677132;
$green400: #719527;
$green400_100: rgba(113, 149, 39, 0.5);
$green400_200: rgba(113, 149, 39, 0.9);

$grey100: #F4F4F4;
$grey200: #C4C4C4;
$grey300: #DCDCDC;
$grey400: #7C7C7C;

$black-gradient: rgba(0, 0, 0, 0.25);

$white-gradient100: rgba(255, 255, 255, 0.25);
$white-gradient200: rgba(255, 255, 255, 0.85);
