@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?126gy');
  src:  url('/assets/fonts/icomoon.eot?126gy#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?126gy') format('truetype'),
    url('/assets/fonts/icomoon.woff?126gy') format('woff'),
    url('/assets/fonts/icomoon.svg?126gy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-projector:before {
  content: "\e900";
}
.icon-terrace:before {
  content: "\e912";
}
.icon-livingroom:before {
  content: "\e913";
}
.icon-minibar:before {
  content: "\e914";
}
.icon-maneechan-logo:before {
  content: "\e915";
}
.icon-bathrobe:before {
  content: "\e916";
}
.icon-bedroom:before {
  content: "\e917";
}
.icon-maneechan2:before {
  content: "\e918";
}
.icon-tour:before {
  content: "\e919";
}
.icon-wifi:before {
  content: "\e91a";
}
.icon-presentation:before {
  content: "\e92b";
}
.icon-conference:before {
  content: "\e92c";
}
.icon-gym:before {
  content: "\e92d";
}
.icon-garden:before {
  content: "\e92e";
}
.icon-phone:before {
  content: "\e92f";
}
.icon-mail:before {
  content: "\e930";
}
.icon-playground:before {
  content: "\e931";
}
.icon-facebook:before {
  content: "\e932";
}
.icon-massage:before {
  content: "\e933";
}
.icon-line:before {
  content: "\e934";
}
.icon-pool:before {
  content: "\e935";
}
.icon-email:before {
  content: "\e936";
}
.icon-maneechan-text:before {
  content: "\e937";
}
.icon-menu:before {
  content: "\e938";
}
.icon-breakfast:before {
  content: "\e93a";
}
.icon-person:before {
  content: "\e944";
}

