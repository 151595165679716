#rooms {
  p {
    color: $grey400;
    line-height: 2em;
    @media (min-width: 992px) {
      font-size: 1.25em;
    }
  }
  .hotel-rooms {
    margin-bottom: 2em;
    &:first-child {
      margin-top: -3em;
      .card {
        margin-top: -2em;
        margin-bottom: 5em;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    .room-booknow-btn {
      position: absolute;
      bottom: 1em;
      transform: translateX(-50%);
      left: 50%;
      color: white;
      font-size: 0.5em;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 1.5em 3em;
      letter-spacing: 2px;

      display: flex;
      align-items: center;
      a {
        font-size: 2.5em;
        color: white;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  a.underline {
    color: white;
    text-decoration: underline;
  }
  .room-notes {
    font-size: 0.8em;
    color: $grey400;
    // @media (max-width: 991px) {
    //   margin-top: 2em;
    // }
  }
  .card {
    .bg-img {
      height: 250px;
      @media (min-width: 768px) {
        height: 300px;
      }
    }
    .logo {
      font-size: 1.8em;
      color: $green300;
      margin-right: 0.5em;
    }
    .text {
      font-size: 0.8em;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 1.5em;
      li {
        display: flex;
        margin-bottom: 0.2em;
      }
    }
    .room-rates-btn {
      font-size: 0.8em;
      margin-bottom: 1em;
      line-height: 3em;
      display: inline-block;
    }
    .rates {
      font-size: 0.7em;
      th {
        text-align: center;
        vertical-align: middle;
        border: none;
        width: 33%;
      }
      tbody {
        position: relative;
      }
      td {
        text-align: center;
      }
      .thead-inverse th {
        background-color: $green300;
      }
      .titles {
        font-size: 1.25em;
      }
      .prices {
        font-size: 1.5em;
      }
      .notes {
        // position: absolute;
        right: 0;
        bottom: 0;
        td {
          margin: 0;
          border: none;
        }
        .content {
          display: flex;
          flex-direction: column;
          text-align: right;
        }
      }
    }
    .table {
      font-size: 10px;
      margin: 0;
      &.hide-mobile {
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
  }

  .remarks {
    font-size: 0.8em;
    line-height: 2em;
  }

  .booknow-wrapper {
    margin-bottom: 4em;
    .btn-primary {
      padding: 1em 3em;
    }
  }
  .room-specs {
    .left .spec{
      justify-content: flex-end;
      text-align: right;
    }
    .right .spec{
      justify-content: flex-start;
    }
    .spec {
      display: flex;
      align-items: center;
      margin: 1em 0;
      min-height: 3em;
      img {
        width: 32px;
      }
    }
    .logo {
      font-size: 2em;
      padding: 0 0.5em;
      color: $green300;
    }
  }
  .room-name {
    position: absolute;
    left: 1em;
    top: 1em;
    color: white;
    font-size: 1.25em;
    letter-spacing: 5px;
    z-index: 10;
  }
  .box-img {
    height: 300px;
    border: 5px solid $grey100;
    &.no-left-border {
      border-left: none;
    }
    &.no-right-border {
      border-right: none;
    }
  }

  .booknow-btn {
    background: none;
    border: none;
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 1.5em;
  }

  .room-details {
    width: 100%;
    position: absolute;
    color: white;
    background-color: $green400_200;
    top: 0;
    z-index: 20;
    display: none;
    @media only screen and (max-width: 992px) {
      &.mobile {
        display: block;
        position: initial;
        top: initial;
        background-color: white;
        color: $black100;
        border-top: 1px solid $grey100;
      }
    }
    ul {
      list-style: initial;
      li {
        display: list-item;
      }
    }
  }

  .awards {
    align-items: center;
    justify-content: space-around;
    @media (min-width: 992px) {
      margin-top: 5em;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    .sha-plus {
      height: 90px;
    }
    .safe-travels {
      height: 90px;
    }
  }
}
