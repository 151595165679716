#home {
  h1 {
    color: $green100;
    @media (max-width: 991px) {
      font-size: 1.5em;
    }
  }
  h5 {
    line-height: 1.75em;
    @media (max-width: 991px) {
      font-size: 1em;
    }
  }
  .img-info {
    margin-top: 10em;
    .img-frame {
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
  .info {
    line-height: 2em;
    img {
      margin: 0 auto;
      width: 600px;
      display: block;
      margin-top: 0em;
      position: relative;
      top: -6em;
      margin-bottom: -6em;
    }
    p {
      margin: 2em auto 0;
      width: 80%;
      font-size: 1.2em;
      line-height: 1.75em;
      @media (max-width: 991px) {
        font-size: 1em;
      }
    }
  }

  #cbt {
    .card {
      width: 90%;
      margin: 0 auto;
      .row {
        @media (max-width: 991px) {
          height: initial;
        }
      }
      p {
        line-height: 1.75em;
        color: $grey400;
      }
      img {
        width: 125px;
      }
      .highlight-bar {
        position: absolute;
        top: -10px;
        left: 5px;
        width: 100px;
      }
    }
  }

  .page-break {
    .icon {
      background-color: $grey100;
    }
  }

  #room {
    // padding-top: 3rem;
    @media (max-width: 768px) {
      padding-top: initial;
    }
    .rooms {
      .awards {
        padding-top: 5em;
      }
    }
    .info {
      padding-top: 3em;
      padding-left: 5em;
      padding-bottom: 3em;
      @media (max-width: 991px) {
        padding: 3em 2em;;
      }
    }
    .description {
      padding: 8em 3em;
      padding-bottom: 0;
      h5 {
        border-bottom: 1px solid $grey300;
        border-top: 1px solid $grey300;
        padding: 0.5em;
      }
    }
    .room-slide {
      height: 730px;
      .bg-img {
        height: 100%;
      }
      .room-img-1 {
        background-image:url('/assets/images/room_side_hero.jpg');
      }
      .room-img-2 {
        background-image:url($room_001);
      }
      .room-img-3 {
        background-image:url($room_002);
      }
    }
    .room-type {
      margin-bottom: 2em;
      width: 100%;
      @media (max-width: 543px) {
        display: inherit;
      }
      .room-img {
        height: 150px;
        @media (max-width: 991px) {
          height: 200px;
        }
        @media (max-width: 543px) {
          width: 100%;
          height: 200px;
          margin-bottom: 1em;
        }
      }
      .details {
        flex: 1;
        @media (max-width: 543px) {
          text-align: center;
        }
        .name {
          line-height: 1.5em;
          color: inherit;
          @include font(crimson-regular);
        }
        .text {
          line-height: 1.5em;
          color: $grey400;
          font-size: 0.8em;
          ul {
            padding-left: 2em;
            margin: 0;
          }
        }
      }
    }
  }

  #conference {
    padding: 5em 0;
    .main-image {
      height: 300px;
      margin: 10px;
    }
    .secondary-image {
      height: 160px;
      &.image-1 {
        margin: 0 5px 10px 10px;
      }
      &.image-2 {
        margin: 0 10px 10px 5px;
      }
    }
    p {
      line-height: 1.75em;
      color: $grey400;
    }
  }

  #food {
    .bg-img {
      border: 5px solid white;
    }
    .main-img {
      background-image: linear-gradient($black-gradient, $black-gradient),
      url('/assets/images/food/food_1.jpg');
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .title {
        margin-bottom: 5em;
      }
    }
    p {
      padding: 2em;
      line-height: 1.75em;
      color: $grey400;
      margin: 0;
    }
    .full-height {
      height: 500px;
    }
    .half-height {
      height: 250px;
    }
    .no-right-border {
      border-right: none;
    }
    .no-left-border {
      border-left: none;
    }
  }

  #services {
    .title {
      display: inline-block;
      text-align: left;
    }
    .bg-img {
      height: 250px;
      @media (max-width: 543px) {

      height: 300px;
      }
    }
    .info {
      .details {
        margin-top: 0.5em;
        line-height: 1.5em;
        color: $grey400;
        font-size: 0.8em;
        min-height: 65px;
        @media (max-width: 991px) {
          min-height: 80px;
        }
      }
    }
    .flex-center {
      display: flex;
      justify-content: center;
    }
    .card {
      margin-bottom: 2.5em;
      box-shadow: 0px 2px 2px 0px $grey200;
      &:hover {
        transition: box-shadow 0.25s;
        box-shadow: 0px 5px 10px 0px $grey200;
      }
    }
    .service-row {
      display: flex;
      justify-content: center;
      a {
        color: inherit;
      }
    }
  }

  #map {
    .contact {
      background-color: white;
    }
    p {
      line-height: 1.75em;
      color: $grey400;
    }
    .title {
      margin-top: 0 !important;
    }
    .list-info {
      display: flex;
      margin-bottom: 0.5em;
      span {
        font-size: 1.5em;
      }
    }
  }

  .video {
    iframe {
      height: 500px;
      @media (max-width: 991px) {
        height: 400px;
      }
    }
  }
  .awards {
    justify-content: space-around;
    margin-top: 3em;
    img {
      height: 100px;
      object-fit: contain;
    }
  }
}

.btn-details {
  color: $green200;
  font-size: 0.75em;
  letter-spacing: 2px;
  text-decoration: underline;
  padding: 0;
}

.title {
  @include letter-spacing(1px);
  small {
    @include letter-spacing(2px);
    font-size: 12px;
    color: $grey200;
  }
  & > div {
    @include font(playfair-bold);
    font-size: 1.75em;
    margin-top: -5px;
    text-shadow: 0px 2px 0 $grey300;
    @media (max-width: 991px) {
      font-size: 1.5em;
    }
  }
  &.inverse {
    small {
      color: $grey300;
    }
    & > div {
      color: white;
      text-shadow: 0px 2px 0 $grey400;
    }
  }
}

#header #hero .bg-img .container {
  .home-page-title {
    // bottom: 10em;
    .main-title {
      font-size: 2em;
      margin-bottom: 0.25em;
      letter-spacing: 8px;
      @media (max-width: 543px) {
        font-size: 1.5em;
        letter-spacing: 4px;
      }
    }
    .sub-title {
      font-size: 1.35em;
      @media (max-width: 543px) {
        font-size: 1em;
      }
    }
    span {
      font-size: 1.15em;
    }
    button.btn-primary {
      font-size: 0.3em;
      padding: 1.25em 3em;
    }
    .ads-wording {
      font-size: 1.2em;
      font-weight: 200;
      letter-spacing: 1px;
      margin-top: 2em;
      @media (max-width: 543px) {
        font-size: 0.8em;
        margin-top: 1em;
      }
    }
    .direct-book-wrapper {
      padding-top: 2em;
      @media (max-width: 543px) {
        padding-top: 0.25em;
      }
    }
  }
}
