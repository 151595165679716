#our-service {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 2em;
    }
  }
  .images {
    img {
      padding: 5px;
      object-fit: cover;
      height: 300px;
    }
  }
}
