#facilities {
  .main-bg-img {
    height: 400px;
    border: 5px solid white;
    @media (max-width: 543px) {
      border: none;
      margin-top: 2em;
    }
  }
  .sub-bg-img {
    height: 180px;
    border: 5px solid white;
  }
  .border-right-none {
    border-right: none;
  }
  .border-left-none {
    border-left: none;
  }

  p {
    color: $grey400;
    line-height: 2em;
  }
  .topic {
    margin-top: 6em;
    @media (max-width: 543px) {
      margin-top: initial;
      box-shadow: 0px 10px 10px 0px $grey100;
      padding-top: 3em;
    }
  }

  @include page-break-icon-bg(white);

  .mobile-column-reverse {
    @media (max-width: 543px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .menu-container {
    display: none;
  }
  .menu {
    background: rgba(113,149,39,0.7);
    position: fixed;
    z-index: 20;
    list-style: none;
    margin: 0;
    padding: 0;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    li {
      text-align: center;
      a {
        color: white;
        display: block;
        padding: 0.5em;
        min-width: 5em;
        .logo {
          font-size: 2.5em;
        }
        div:last-child {
          font-size: 0.5em;
        }
        &:hover, &:focus {
          text-decoration: none;
          background-color: rgba(103, 113, 50, 0.7);
        }
      }
    }
  }
}
