@font-face {
  font-family: 'playfair-bold';
  src: url('/assets/fonts/playfairdisplay-bold-webfont.woff2') format('woff2'),
        url('/assets/fonts/playfairdisplay-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair-regular';
  src: url('/assets/fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
        url('/assets/fonts/playfairdisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'crimson-regular';
  src: url('/assets/fonts/crimsontext-roman-webfont.woff2') format('woff2'),
       url('/assets/fonts/crimsontext-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
