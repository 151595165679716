@mixin bg-img($name) {
  background-position: center;
  background-size: cover;
  height: 700px;
  background-image:
    linear-gradient($black-gradient, $black-gradient),
    url($name);
  @media (max-width: 991px) {
    height: 450px;
    background-attachment: initial;
  }
}

.hero-slide {
  background-attachment: fixed;
  &.main {
    .img-1 {
      @include bg-img($swimming_pool_001);
    }
    .img-2 {
      @include bg-img($garden_002);
    }
    .img-3 {
      @include bg-img($room_001);
    }
    .img-4 {
      @include bg-img($swimming_pool_003);
    }
  }
  &.rooms {
    .img-1 {
      @include bg-img($room_001);
    }
    .img-2 {
      @include bg-img($room_002);
    }
    .img-3 {
      @include bg-img($room_hero);
    }
  }
  &.dining {
    .img-1 {
      @include bg-img($food_001);
    }
    .img-2 {
      @include bg-img($food_002);
    }
    .img-3 {
      @include bg-img($dining_hero);
    }
  }
  &.facilities {
    .img-1 {
      @include bg-img($facility_hero);
    }
    .img-2 {
      @include bg-img($gym_001);
    }
    .img-3 {
      @include bg-img($kid_zone_001);
    }
  }
  &.events {
    .img-1 {
      @include bg-img($event_hero);
    }
    .img-2 {
      @include bg-img($banquet_001);
    }
    .img-3 {
      @include bg-img($banquet_002);
    }
  }
  &.weddings {
    .img-1 {
      @include bg-img($wedding_hero);
      background-position: 80% 50%;
    }
    .img-2 {
      @include bg-img($wedding_001);
    }
    .img-3 {
      @include bg-img($wedding_002);
    }
    .img-4 {
      @include bg-img($wedding_003);
    }
    .img-5 {
      @include bg-img($wedding_004);
    }
    .img-6 {
      @include bg-img($wedding_005);
    }
    .img-7 {
      @include bg-img($wedding_006);
    }
  }
  &.destinations {
    .img-1 {
      @include bg-img($destination_hero);
    }
    .img-2 {
      @include bg-img($hotel_001);
    }
  }
  &.our_service {
    .img-1 {
      @include bg-img($destination_hero);
    }
    .img-2 {
      @include bg-img($hotel_001);
    }
  }
  &.contact_us {
    .img-1 {
      @include bg-img($contact_us_hero);
    }
    .img-2 {
      @include bg-img($hotel_hero);
    }
  }
  &.green_awards {
    .img-1 {
      @include bg-img($contact_us_hero);
    }
    .img-2 {
      @include bg-img($hotel_hero);
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: initial;
}

.slick-prev,
.slick-next {
  z-index: 10;
  width: 35px;
  height: 35px;
  &:before {
    font-size: 35px;
  }
  @media (max-width: 543px) {
    display: none !important;
  }
}

.slick-next {
  right: 20px;
}
.slick-prev {
  left: 20px;
}

.slick-dots {
  z-index: 10;
  bottom: 25px;
  li {
    &.slick-active {
      button:before {
        color: white;
      }
    }
    button:before {
      font-size: 12px;
      color: $grey200;
    }
  }
}

.room-slide {
  .slick-list,
  .slick-track {
    height: 100%;
  }
}
