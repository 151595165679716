@mixin font($name) {
  font-family: $name,"Helvetica Neue",Arial,sans-serif;
}

@mixin letter-spacing($px) {
  letter-spacing: $px;
}

body {
  color: $black100;
  &.font-ready {
    .play-font {
      @include font(playfair-regular);
    }
    .playbold-font {
      @include font(playfair-bold);
    }
    .crimson-font {
      @include font(crimson-regular);
    }
  }
}

.card {
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 10px 0px $grey200;
}

.grey-bg {
  background-color: $grey100;
}

.bg-img {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.spacing-1 {
  @include letter-spacing(1px);
}
.spacing-2 {
  @include letter-spacing(2px);
}
.spacing-3 {
  @include letter-spacing(3px);
}
.spacing-4 {
  @include letter-spacing(4px);
}
.spacing-5 {
  @include letter-spacing(5px);
}

.img-frame {
  // border: 8px solid white;
  box-shadow: 0px 5px 10px 0px $grey200;
}

.deco-none {
  &:hover {
    text-decoration: none;
  }
  text-decoration: none;
}

.my-6 {
  margin-top: 4em;
  margin-bottom: 4em;
}

.mt-6 {
  margin-top: 4em;
}

.my-7 {
  margin-top: 5em;
  margin-bottom: 5em;
}


.inline-block {
  display: inline-block;
}

.visibility-hidden {
  visibility: hidden;
}
.text-underline {
  text-decoration: underline;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-align-center {
  align-items: center;
}

.btn-primary {
  border-radius: 0 !important;
  border: none !important;
  background-color: $green400 !important;
  &:hover {
    background-color: $green300 !important;
  }
}
.pointer-none {
  pointer-events: none;
}

@mixin page-break-icon-bg($color) {
  .page-break {
    .icon {
      background-color: $color;
    }
  }
}

.page-break {
  hr {
    border-color: $grey300;
  }
  .icon {
    padding: 0 1.5em;
    position: absolute;
    font-size: 25px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -115%);
    color: $grey300;
  }
}

.map-container {
  iframe {
    pointer-events: none;
  }
  &.clickable {
    .map-exit-btn {
      display: block;
    }
    iframe {
      pointer-events: auto;
    }
    .map-enter-btn {
      display: none;
    }
  }
  .map-enter-btn {
    display: block;
  }
  .map-exit-btn {
    display: none;
  }
  .map-exit-btn,
  .map-enter-btn {
    position: absolute;
    bottom: 3em;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5em 2em;
    background-color: black;
    color: white;
    border-radius: 20px;
    opacity: 0.5;
    z-index: 10;
  }
  @media (min-width: 992px) {
    .map-exit-btn,
    .map-enter-btn {
      display: none;
    }
    iframe {
      pointer-events: auto;
    }
  }
}

// .home-page-title {
  .direct-book {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    @media (max-width: 543px) {
      margin-bottom: 1em;
    }
    &:hover {
      ul {
        display: flex;
      }
    }
    ul {
      height: 50px;
      position: absolute;
      width: 100%;
      bottom: -50px;
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      justify-content: center;
      background-color: $green400_100;
      @media (max-width: 543px) {
        display: flex;
      }
      li {
        display: flex;
        align-items: center;
      }
      a {
        text-decoration: none;
        padding: 0 0.5em;
        &:hover {
          text-decoration: none;
        }
        font-size: 1.5rem;
        color: white;
      }
    }
  }
// }

.modal-fit {
  max-width: 95%;
  margin: 1em auto;
}

// .trip_advisor_restaurant {
//   position: fixed;
//   right: 0;
//   bottom: 100px;
//   z-index: 20;
//   box-shadow: 0px 2px 5px $black100;
// }

#line-chat {
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 20;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: initial;
  &:hover {
    text-decoration: none;
  }
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 1px 2px $grey200;
  }
  .bubble {
    position: relative;
    margin-right: 10px;
    .message {
      padding: 0.5em 0.8em;
      background-color: #FFF;
      box-shadow: 0 1px 2px $grey200;
      border-radius: 10px;
    }
    .arrow-right {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #FFF;
      position: absolute;
      right: -6px;
      top: 9px;
    }
  }
}
