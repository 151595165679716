#hotel-gallery {
  .main-container {
    letter-spacing: 1px;
    padding-top: 4em;
    padding-bottom: 5em;
    margin-top: 9em;
    margin-bottom: 5em;
    background: white;
    box-shadow: 0px 3px 5px 0px $grey300;
  }
  h5 {
    color: $grey400;
  }
  span {
    font-size: 0.8em;
  }
}
