#weddings {
  .gallery-container {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  h4, h5 {
    letter-spacing: 1px;
  }
  h5 {
    color: $grey400;
  }

  @include page-break-icon-bg(white);
}
