#contact-us {
  .contact-us-info {
    margin: 8em 0 3em;
  }
  .socials {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        text-decoration: none;
      }
    }
    i, .icon {
      font-size: 1.3em;
    }
  }
}
