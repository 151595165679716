#footer {
  background-color: $black100;
  color: white;
  padding: 4em 0;
  .container {
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  a.logo {
    color: white;
    text-decoration: none;
    font-size: 2.5em;
    margin-right: 1em;
    @media (max-width: 991px) {
      margin-right: 0;
    }
  }
  .menu-wrapper {
    display: flex;
    @media (max-width: 991px) {
      margin-bottom: 2em;
    }
    @media (max-width: 543px) {
      flex-direction: column;
    }
  }
  ul {
    font-size: 0.8em;
    list-style: none;
    margin: 0;
    padding: 0;
    &.menu {
      display: flex;
      flex: 1;
    }
    a {
      text-decoration: none;
      color: white;
      &:hover {
        color: $grey400;
      }
    }
    &.menu {
      @media (max-width: 1200px) {
        display: initial;
        text-align: left;
        line-height: 2em;
      }
      li {
        margin-right: 2em;
      }
    }
    &.socials {
      font-size: 0.7em;
      span.icon {
        font-size: 2em;
        margin-right: 0.5em;
      }
      li {
        margin-bottom: 0.35em;
      }
      a {
        display: flex;
        align-items: center;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
