#header {
  #top-menu {
    background-color: white;
    .menu-container {
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    .menu {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
      align-items: center;
      li {
        margin-right: 1.5em;
        &:last-child {
           margin: inherit;
        }
        a {
          color: $black100;
          display: flex;
          text-decoration: none;
        }
        span {
          font-size: 1.5em;
          margin-right: 0.25em;
        }
        &.cbt {
          img {
            height: 15px;
            margin-right: 0.25em;
          }
        }
        @media (max-width: 991px) {
          margin-right: 1em;
          a {
            font-size: 0.8em;
          }
        }
        .locale {
          display: flex;
          box-shadow: 0px 2px 5px 0px $grey200;
          a {
            padding: 5px 15px;
            color: $grey400;
            &.active {
              background-color: #5cb85c;
              color: white;
            }
          }
        }
      }
    }
  }
  #mobile {
    .menu-btn {
      padding: 5px 10px;
      font-size: 1.25em;
      background-color: transparent;
      border: 1px solid $white-gradient200;
      color: white;
      outline: none;
      cursor: pointer;
    }
    ul {
      opacity: 0;
      position: absolute;
      right: 0;
      list-style: none;
      background-color: $white-gradient200;
      margin: 0;
      line-height: 2.5em;
      text-align: center;
      padding: 0.5em 0;
      width: 100%;
      z-index: -10;
      a {
        color: black;
      }
    }
    &.selected {
      .menu-btn {
        background-color: $white-gradient200;
        color: black;
        transition: background-color color 0.25s;
      }
      ul {
        z-index: 10;
        opacity: 1;
        transition: opacity 0.25s;
      }
    }
  }

  #hero {
    .logo {
      color: white;
      font-size: 65px;
      @media (max-width: 991px) {
        font-size: 45px;
      }
    }
    nav {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul.desktop {
        border-bottom: 1px solid $white-gradient100;
        display: inline-flex;
        list-style: none;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        li {
          padding-right: 2em;
          &:last-child {
            padding-right: inherit;
          }
          &:hover, &.selected {
            a {
              border-bottom: 6px solid white;
              padding-bottom: 10px;
            }
          }
          a {
            color: white;
            text-decoration: none;
          }
          @media (max-width: 1200px) {
            padding-right: 1.5em;
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
    .bg-img {
      .container {
        position: relative;
        height: 100%;
        .page-title {
          text-align: center;
          position: absolute;
          bottom: 7em;
          left: 50%;
          transform: translateX(-50%);
          color: white;
          font-size: 18px;
          line-height: 2em;
          width: 100%;
          > div {
            font-size: 2.5em;
            line-height: 1.25em;
          }
        }
      }
    }
  }
}

#header {
  #hero {
    .bg-img {
      height: 700px;
      background-attachment: fixed;
      position: absolute;
      top: 3em;
      width: 100%;
      @media (max-width: 991px) {
        height: 500px;
        background-attachment: initial;
      }
    }
  }
  &.green {
    #hero .bg-img {
      height: 110px;
      background-color: #719526;
    }
  }
}
