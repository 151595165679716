#membership {
  margin-top: 18em;
  margin-bottom: 5em;
  .img {
    height: 220px;
    background-size: cover;
    background-position: center;
    margin-top: 2em;
  }
}
